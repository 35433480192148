import React from 'react';
import translate from "../../i18n/translate";
import logo from "../../assets/images/bolttech_logo_footer.svg";
const Footer = function () {
  return (
    <>
      <div className="main-footer">
        <div className="container">
          <div className="footer-logo">
            <img src={logo} alt="" width="160" />
          </div>
          <div className="linksWrapper"><a href="/tos/index.html" target="_blank">{translate('home.footer.tnc')}</a><a href="/disclaimer/index.html" target="_blank">{translate('home.footer.disclaimer')}</a></div>
          <div className="copyrights">
            <div className="row">
              <div className="col-md-7 copyright">{translate('home.footer.copyrights')} <span className="display-desktop display-desktop-inline-block">{translate('home.footer.allRightsReserved')}</span></div>
              <div className="col-md-5 text-right display-desktop">{translate('home.footer.thePower', {bold:(chunk)=><b>{chunk}</b>})}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Footer;